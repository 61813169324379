var site = site || {};
var generic = generic || {};

(function ($) {
  Drupal.behaviors.countrypicker = {
    attach: function (context) {
      var prefCountry, prefLanguage, locale, cartItemCount;

      locale = generic.cookie('LOCALE'); // get locale settings from browser if it's set
      if (locale != null && locale !== '') {
        var localeParts = locale.split('_');

        prefLanguage = localeParts[0].toLowerCase();
        prefCountry = localeParts[1].toLowerCase();
        setupLanguageMenu($('#countryPickerWrap', context), prefCountry, prefLanguage);

        $('#countryPickerWrap .language-list li', context).on('click', function (e) {
          e.preventDefault();
          site.changeLocale(prefCountry, $(this).data('language'));
        });
      }

      $('#countryPickerWrap .country-list a', context).off('click');

      $('#countryPickerWrap .country-list a', context).each(function () {
        $(this).on('click', function () {
          // user may be switching country. is their current language going to be supported when they land or should we choose the target country's default?
          prefLanguage = site.getAltLanguage(prefLanguage, $(this).data('languages').split(','));
          requestChangeLocale($(this).data('country'), prefLanguage, $(this).data('countryname'));
        });
      });

      $('.mobileCountryLink', context).off('click');

      $('.mobileCountryLink', context).on('click', function () { // mobile-specific country link has different behaviour
        requestChangeLocaleMobile($(this).data('countryname'));
      });

      cartItemCount = 0;
      $(document).on('addToCart.success', function (e, cartResponse) {
        if (cartResponse.trans_data && cartResponse.trans_data.items_count) {
          cartItemCount = cartResponse.trans_data.items_count;
        } else {
          cartItemCount = cartResponse;
        }
      });

      function requestChangeLocale(prefC, prefL, countryName) {
        prefCountry = prefC;
        prefLanguage = prefL;

        if (cartItemCount > 0) { // if cart has items, show the warning
          $('#localeSwitchWarning').html($('#localeSwitchWarning').html().replace('{{{country_name}}}', '<span id="#destinationCountryname">' + countryName + '</span>'));
          $('#destinationCountryname').html(countryName);

          $('body').append($('#localeSwitchOverlay').css({ display: 'block' }));
          site.centerModal($('#localeSwitchWarning'));
        } else { // if not, change straight away
          site.changeLocale(prefCountry, prefLanguage);
        }
      }

      function requestChangeLocaleMobile(countryName) {
        if (cartItemCount > 0) { // if cart has items, show the warning
          $('#localeSwitchWarning').html($('#localeSwitchWarning').html().replace('{{{country_name}}}', '<span id="#destinationCountryname">' + countryName + '</span>'));
          $('#destinationCountryname').html(countryName);

          $('body').append($('#localeSwitchOverlay').css({ display: 'block' }));
          site.centerModal($('#localeSwitchWarning'));
        } else { // if not, show the interstitial
          $('body').trigger('displayLocaleInterstitial');
        }
      }

      function setupLanguageMenu($container, prefCountry, prefLanguage) { // takes the list of countries and supported languages and creates separate country and language links
        $container.find('.country-list li').each(function () {
          var $link = $(this).find('a');

          if ($link.data('offsite')) {
            $container.find('.country-list-offsite').append($(this)); // if country is offsite add it to the separate offsite country list
          } else {
            if ($link.data('country').toLowerCase() === prefCountry) { // if this is the user's selected country
              $('.mobileCountryLink').html($link.html()); // create the mobile link, which looks like this flag and country name
              $('.mobileCountryLink').data('countryname', $link.data('countryname'));
              $('#countryPickerLink').html($link.data('countryname')); // set the dropdown flag to be this country's flag
              $link.addClass('current');
              $link.addClass('selected');
              if (!prefLanguage) {
                prefLanguage = $link.data('languages').split(',')[0]; // set preferred language to country's default if not defined
              }
              $container.find('.language-list').html(site.getLangsFromCountryLink($link).join(''));
              $container.find('.language-list li').removeClass('current-lang');
              $container.find('.language-list li').each(function () {
                if ($(this).data('language') === prefLanguage) {
                  $(this).addClass('current-lang');
                  $('#languagePickerLink').text($(this).text());
                }
              });
            }
          }
        });
      }
    }
  };
})(jQuery);
